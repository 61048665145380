<template>
    <div class="sms_user_list">
        <el-card>
            <nav class="input" style="width: auto">
                <el-input v-model="search.keyword" size="medium" placeholder="请输入名称" />
            </nav>
            <nav class="input" style="width: 150px">
                <el-button size="small" @click="reset">重置</el-button>
                <el-button type="primary" size="small" @click="$refs.table.reload()">{{ "搜索" }}</el-button>
            </nav>
            <nav class="input" style="width: auto">
                <el-button type="primary" size="small" @click="add">{{ "创建任务" }}</el-button>
            </nav>
        </el-card>
        <el-card style="margin-top: 10px">
            <z-table ref="table" v-model="list" @query="queryList" fullscreenLoading>
                <el-table :data="list" style="width: 100%" border stripe :header-cell-style="rowClass">
                    <el-table-column prop="id" label="ID"> </el-table-column>
                    <el-table-column prop="title" label="任务名称"> </el-table-column>
                    <el-table-column prop="template" label="短信模板"> </el-table-column>
                    <el-table-column prop="tag" label="收件人标签"> </el-table-column>
                    <el-table-column prop="totalNum" label="预计发送人数"> </el-table-column>
                    <el-table-column prop="realNum" label="实际发送人数"> </el-table-column>
                    <el-table-column prop="status" label="状态">
                        <template #default="{row}">
                            <el-tag v-if="row.status == 0" type="info" size="small">未发送 </el-tag>
                            <el-tag v-if="row.status == 1" size="small">已发送</el-tag>
                        </template>
                    </el-table-column>
                    <el-table-column prop="status" label="发送时间类型">
                        <template #default="{row}">
                            {{ row.sendTimeType == 0 ? "立即发送" : "定时发送" }}
                            <div v-if="row.sendTimeType == 1">
                                {{ row.sendTime }}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="manager" label="创建人"> </el-table-column>
                    <el-table-column prop="remark" label="备注">
                        <template #default="{row}">
                            <Remark :row="row" url="/admin/Sms/editTaskRemark"></Remark>
                        </template>
                    </el-table-column>
                    <el-table-column prop="createTime" label="创建时间"> </el-table-column>

                    <el-table-column label="操作">
                        <template slot-scope="{ row }">
                            <!-- <el-button
                                @click="management(row)"
                                style="color: sandybrown;"
                                type="text"
                                icon="el-icon-s-help"
                                size="mini"
                                >管理收件人</el-button
                            >

                            <el-button @click="editor(row)" type="text" icon="el-icon-edit" size="mini">编辑</el-button> -->
                          <el-button @click="handleView(row)" type="text" icon="el-icon-setting"
                          size="small"
                          style="color: green">
                            查看收件人
                          </el-button>
                          <el-button
                                @click="onDelete(row)"
                                type="text"
                                icon="el-icon-delete"
                                size="mini"
                                style="color: red;"
                                >删除</el-button
                            >
                        </template>
                    </el-table-column>
                </el-table>
            </z-table>
        </el-card>
        <pop ref="pop" :title="edit ? '修改收件人标签' : '创建短信任务'" @toAudit="toAudit">
            <el-form :model="form" ref="form" :rules="rules" label-width="auto" :inline="false" size="normal">
                <el-form-item label="任务名称">
                    <el-input v-model="form.title"></el-input>
                </el-form-item>
                <el-form-item label="短信模板">
                    <fuzzy-selection
                        tipName="短信模板"
                        :type="12"
                        width="100%"
                        :value.sync="form.templateId"
                    ></fuzzy-selection>
                </el-form-item>
                <el-form-item label="收件人标签">
                    <fuzzy-selection
                        tipName="收件人标签"
                        :type="13"
                        width="100%"
                        :value.sync="form.tagId"
                    ></fuzzy-selection>
                </el-form-item>
                <el-form-item label="发送时间">
                    <el-radio v-model="form.sendTimeType" :label="0">立即发送</el-radio>
                    <el-radio v-model="form.sendTimeType" :label="1">定时发送</el-radio>
                    <div v-if="form.sendTimeType == 1">
                        <div-picker v-model="form.sendTime"> </div-picker>
                    </div>
                </el-form-item>
            </el-form>
        </pop>

      <!-- 查看收件人 -->
      <pop ref="recordPop" width="60%" title="收件人列表" @toAudit="poptoAudit">
        <el-card>
          <nav class="input" style="width: 260px;">
            <el-input v-model="popSearch.keyword" size="medium" placeholder="输入姓名，手机号，公司，职位，备注" />
          </nav>
          <nav class="input" style="width: auto;">
            <el-select v-model="popSearch.waId" placeholder="小助手" size="medium">
              <el-option
                  v-for="item in $store.state.NormalList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id + ''"
              />
            </el-select>
          </nav>
          <nav class="input" style="width: auto;">
            <region :val1.sync="popSearch.province" :val2.sync="search.city"></region>
          </nav>
          <nav class="input" style="width: auto;">
            <typeBusinessSubBusiness :form.sync="popSearch"></typeBusinessSubBusiness>
          </nav>
          <nav class="input" style="width: 150px">
            <el-select v-model="popSearch.jobLevel" placeholder="职级" size="medium">
              <el-option label="全部" :value="-1" />
              <el-option
                  v-for="(item, index) in $store.state.config && $store.state.config.userJobLevel"
                  :key="index"
                  :label="item"
                  :value="index"
              />
            </el-select>
          </nav>
          <nav class="input" style="width: 150px">
            <el-button size="small" @click="reset">重置</el-button>
            <el-button type="primary" size="small" @click="$refs.poptable.reload()">{{ "搜索" }}</el-button>
          </nav>


        </el-card>
        <el-card style="margin-top: 10px">
          <z-table ref="poptable" v-model="popList" @query="popqueryList" fullscreenLoading>
            <el-table
                @selection-change="handleSelectionChange"
                :data="popList"
                style="width: 100%"
                border
                stripe
                :header-cell-style="rowClass"
            >
              <el-table-column type="selection" width="40"> </el-table-column>
              <el-table-column prop="id" label="ID"> </el-table-column>
              <el-table-column prop="realName" label="姓名"> </el-table-column>
              <!-- <el-table-column prop="phone" label="手机"> </el-table-column> -->
              <el-table-column prop="company" label="公司"> </el-table-column>
              <el-table-column prop="job" label="职位"> </el-table-column>
              <el-table-column prop="jobLevelName" label="职级"> </el-table-column>
              <el-table-column prop="jobLevelName" label="省市">
                <template #default="{row}"> {{ row.provinceName }} - {{ row.cityName }} </template>
              </el-table-column>
              <el-table-column prop="typeName" label="类型"> </el-table-column>
              <el-table-column prop="businessName" label="行业"> </el-table-column>
              <el-table-column prop="subBusinessName" label="子行业"> </el-table-column>
              <el-table-column prop="remark" label="备注"> </el-table-column>
              <el-table-column prop="assistant" label="小助手"> </el-table-column>
              <el-table-column prop="createTime" label="创建时间"> </el-table-column>

              <!-- <el-table-column label="操作">
                  <template slot-scope="{ row }">
                      <el-button
                          @click="management(row)"
                          style="color: sandybrown;"
                          type="text"
                          icon="el-icon-s-help"
                          size="mini"
                          >管理收件人</el-button
                      >

                      <el-button @click="editor(row)" type="text" icon="el-icon-edit" size="mini">编辑</el-button>
                      <el-button
                          @click="onDelete(row)"
                          type="text"
                          icon="el-icon-delete"
                          size="mini"
                          style="color: red;"
                          >删除</el-button
                      >
                  </template>
              </el-table-column> -->
            </el-table>
          </z-table>
        </el-card>
      </pop>
    </div>
</template>
<script>
import list from "/src/mixin/list.js";
import fuzzySelection from "@/components/fuzzySelection.vue";
import typeBusinessSubBusiness from "@/components/type-business-subBusiness/index.vue";
export default {
    mixins: [list],
    components: {
      typeBusinessSubBusiness,
        fuzzySelection,
    },
    data() {
        return {
          popSearch: {
            keyword: "",
            privince: "",
            city: "",
            waId: "",
            sutId: "",
            type: "",
            business: "",
            subBusiness: "",
            jobLevel: "",
          },
          popList:[],
          title: "加载中...",
          selection: [],
            search: {
                keyword: "",
            },
            form: {
                title: "",
                templateId: "",
                tagId: "",
                sendTimeType: "",
            },
            edit: false,
        };
    },
    methods: {
      popgetlist() {
        this.$refs.poptable.refresh();
      },
      async popqueryList(val) {
        let { data: res } = await this.$http.get("/admin/Sms/getSmsUserList", {
          params: { ...val, ...this.popSearch},
        });
        if (res.errorCode == 200) {
          // this.$message.success(res.message);
          this.$refs.poptable.complete(res.data);
        }
      },
      handleSelectionChange(e) {
        this.selection = e;
      },
      async poponDelete(row) {
        await this.$confirm("此操作将永久删除该, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        });
        var arr = this.selection.map((item) => item.id);
        let { data: res } = await this.$http.post("/admin/Sms/delSmsUser", { ids: arr.toString() });
        if (res.errorCode == 200) {
          this.$message.success(res.message);
          this.getlist();
        }
      },
      popadd() {
        this.form = this.$options.data.call(this).form;
        this.form.tagId = this.$route.query.id;
        this.$refs.pop.show = true;
        this.edit = false;
      },
      async popeditor(row) {
        this.edit = true;
        let { data: res } = await this.$http.get("/admin/Sms/getTagById", { params: { id: row.id } });
        if (res.errorCode == 200) {
          this.$refs.pop.show = true;
          this.form = res.data;
        }
      },
      async poptoAudit() {
       this.$refs.recordPop.show=false
      },
      popFtag(val) {
        if (val == 0) {
          return "待审核";
        }
        if (val == 1) {
          return "审核通过";
        }
        if (val == 2) {
          return "审核不通过";
        }
        return "";
      },
      handleView(row){
        this.popSearch.sutId=row.sutId
        this.popqueryList()
        // this.$refs.poptable.reload()
        this.$refs.recordPop.show=true
      },
      getlist() {
            this.$refs.table.refresh();
        },
        management(val) {
            this.$router.push({
                name: "sms_user_list_management",
                query: { id: val.id, name: val.name },
            });
        },
        async queryList(val) {
            let { data: res } = await this.$http.get("/admin/Sms/getTaskList", {
                params: { ...val, ...this.search,...this.$route.query},
            });
            if (res.errorCode == 200) {
                // this.$message.success(res.message);
                this.$refs.table.complete(res.data);
            }
        },
        async onDelete(row) {
            await this.$confirm("此操作将永久删除该, 是否继续?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            });
            const { id } = row;
            let { data: res } = await this.$http.post("/admin/Sms/delTask", { id });
            if (res.errorCode == 200) {
                this.$message.success(res.message);
                this.getlist();
            }
        },
        add() {
            this.form = this.$options.data.call(this).form;
            this.$refs.pop.show = true;
            this.edit = false;
        },
        async editor(row) {
            this.edit = true;
            let { data: res } = await this.$http.get("/admin/Sms/getTagById", { params: { id: row.id } });
            if (res.errorCode == 200) {
                this.$refs.pop.show = true;
                this.form = res.data;
            }
        },
        async toAudit() {
            let { data: res } = this.edit
                ? await this.$http.post("/admin/Sms/editTag", this.form)
                : await this.$http.post("/admin/Sms/createTask", this.form);
            if (res.errorCode == 200) {
                this.$message.success(res.message);
                this.getlist();
                this.$refs.pop.show = false;
            }
        },
        Ftag(val) {
            if (val == 0) {
                return "待审核";
            }
            if (val == 1) {
                return "审核通过";
            }
            if (val == 2) {
                return "审核不通过";
            }
            return "";
        },
    },
    mounted() {},
};
</script>

<style lang="scss" scoped></style>
